@import "_bootstrap.scss";
@import "fontawesome/font-awesome";
@import "widgets";

body{
    font-family: 'Source Sans Pro', sans-serif;
    font-size:16px;
    color:$gray-dark;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Roboto', sans-serif;
}

h1{
    color:$brand-primary;
    font-size:32px;
    font-weight: 300;
}

h2{
    font-size:22px;
    margin-bottom:5px;
    color:$brand-primary;
}

#top-wrapper{
	margin-top:30px;
}

#content-wrapper{
	margin-top:30px;
}

#footer-wrapper{
	margin-top:30px;
}

.widget-style-border{
	border:1px solid $brand-primary;
}

.widget-style-border-padding{
	@extend .widget-style-border;
	padding:15px;
}

#top-menu{
	list-style-type:none;
	margin-top:22px;
	font-size:16px;
	font-weight:300;
    padding:0;
    float:right;
	li{
		float:left;
		margin-left:10px;
		a, a:link, a:active, a:visited{
			color:$brand-info;
            padding:3px 10px;
            border-radius:3px;
		}
		a:hover{
			color:#fff;
            background-color:$brand-success;
		}
		&.active{
			a, a:link, a:active, a:visited{
				color:#fff;
                background-color:$brand-success;
			}
		}

	}
    &:after{
        clear:both;
    }
    @media(max-width:$screen-xs-max) {
        text-align: center;
        li{
            display: inline-block;
            float:none;
            padding-bottom:10px;
            a{
                padding:8px 15px;
            }
            margin:0;
        }
    }
}

.wc-widget-wrapper{
	margin-bottom:30px;
    @extend .clearfix;
}

#home-banner-wrapper{
    min-height: 450px;
    background-image:url('../images/home-bg.jpg');
    background-position:50%;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom:30px;
    #home-banner-container{
        position: relative;
    }
    #banner-content{
        position: absolute;
        top: 170px;
        right: 0;
        @media (max-width: $screen-sm-max) {
            position: relative;
            width: 100%;
            top:140px;
            text-align: center;
        }
        .title{
            font-size:47px;
            color:$brand-primary;
            display: block;
            font-weight: 500;
            letter-spacing: 2px;
        }
        .subtitle{
            font-size:24px;
            color:$gray;
            text-align: center;
            width:100%;
        }
        @media (max-width: $screen-xs-max){
            .title{
                font-size:28px;
            }
            .subtitle{
                font-size:18px;
            }
        }
    }
}

.sidebar-widget-style > .wc-widget-content{
    background: #FBFBFB;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.11);
    border-radius: 3px;
    padding:15px;
    h2{
        margin-top:0;
    }
}

#footer-wrapper{
    font-size:12px;
    hr{
        border-top:1px solid $gray-light;
        margin:0;
        margin-bottom:10px;
    }
    #footer-left{
        .copyright{
            
            color:$gray-light;
        }
        #footer-logo{
            margin-right:15px;
        }
    }
    #footer-right{
        #footer-menu{
            list-style-type:none;
            float:right;
            li{
                float:left;
                margin:0;
                padding:0;
                margin-left:8px;
                a, a:active, a:visited, a:link{
                    padding:0 2px;
                }
            }
        }
    }
}

.wc-widget-wrapper{

}

.sidebar > .wc-widget-container{
    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        .wc-widget-container-dropzone{ display:none; }
        -moz-column-count: 2;
        -moz-column-gap: 10px;
        -webkit-column-count: 2;
        -webkit-column-gap: 10px;
        column-count: 2;
        column-gap: $grid-gutter-width;
        width:600px;
        margin:0 auto;
        .wc-widget-wrapper{
            -webkit-column-break-inside: avoid;
            column-break-inside: avoid;
            -moz-column-break-inside: avoid;
            margin-bottom:30px;
            padding:5px 0;
        }
    }
}

.blog-item{
    .blog-title-subs{
        font-size:0.8em;
        color:$gray;
        margin-bottom:15px;
    }    
}

.blog-list{
    .blog-item{
        margin-top:30px;
        padding-top:30px;
        border-top:1px solid $gray-lighter;
        &:first-child{
           border-top:0;
           margin-top:0;
           padding-top:0;
        }
        .btn.btn-primary{
            margin-top:15px;
            padding:3px 30px;
        }
    }
    h2{
        margin-top:0;
    }
}